import { FC, useState } from 'react';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { Button } from '@components/Common/Button';
import Dropdown, {
    StyledDropdownSelector,
    StyledDropdownItems,
    StyledDropdownItem,
    Arrow,
} from '@components/Common/Dropdown';
import { IOption } from '@interfaces/common';
import { useTranslation } from '@lib/i18n';

export const SortingFieldWrapper = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto 120px 40px;
    align-items: flex-end;
    gap: 10px;
`;

export const SortingFieldName = styled.div``;

export const SortingFieldTitle = styled.div<{ sortOrder: number }>`
    font-size: 1em;
    font-family: var(--font-700);
    margin-bottom: 6px;

    ${({ sortOrder }) =>
        sortOrder === 1 &&
        `
    color: #1871c2;
    `}

    ${({ sortOrder }) =>
        sortOrder === 2 &&
        `
    color: #e03130;
    `}

    ${({ sortOrder }) =>
        sortOrder === 3 &&
        `
        color: #2f9e44;
        `}
`;

export const SortingFieldDirection = styled.div``;

export const SortingFieldReset = styled.div`
    display: flex;
    align-items: center;

    button {
        height: 36px;
    }
`;

const SORTING_DIRECTIONS: { value: 'ASC' | 'DESC' | undefined; label: string }[] = [
    { value: undefined, label: '-' },
    { value: 'ASC', label: 'ASC' },
    { value: 'DESC', label: 'DESC' },
];

interface IProps {
    sortOrder: number;
    fieldOptions: IOption[];
    availiableFieldOptions: IOption[];
    field?: string;
    direction?: 'ASC' | 'DESC';
    showResetButton?: boolean;
    disabled?: boolean;
    onChangeField: (field: string) => void;
    onChangeDirection: (direction?: 'ASC' | 'DESC') => void;
    onReset: () => void;
}

export const SortingField: FC<IProps> = ({
    sortOrder,
    availiableFieldOptions,
    fieldOptions,
    field,
    direction,
    showResetButton,
    disabled,
    onChangeDirection,
    onChangeField,
    onReset,
}) => {
    const { t } = useTranslation('demo');

    const [visibleFieldSelector, setVisibleFieldSelector] = useState(false);
    const [visibleDirectionSelection, setVisibleDirectionSelection] = useState(false);

    return (
        <SortingFieldWrapper>
            <SortingFieldName>
                <SortingFieldTitle sortOrder={sortOrder}>
                    {t('sorting.fieldTitle', { sortOrder })}
                </SortingFieldTitle>

                <Dropdown
                    visible={visibleFieldSelector}
                    onVisibleChange={(visible) =>
                        setVisibleFieldSelector(visible && !disabled)
                    }
                    trigger={['click']}
                    overlay={
                        <StyledDropdownItems>
                            {availiableFieldOptions.map((option) => (
                                <StyledDropdownItem
                                    key={option.value}
                                    onClick={() => {
                                        onChangeField(option.value);
                                        setVisibleFieldSelector(false);
                                    }}
                                    className="dropdown-item"
                                >
                                    {option.label}
                                </StyledDropdownItem>
                            ))}
                        </StyledDropdownItems>
                    }
                >
                    <StyledDropdownSelector disabled={disabled}>
                        {fieldOptions.find((option) => option.value === field)?.label ||
                            '–'}

                        <Arrow size={14} />
                    </StyledDropdownSelector>
                </Dropdown>
            </SortingFieldName>

            <SortingFieldDirection>
                <SortingFieldTitle sortOrder={sortOrder}>
                    {t('sorting.directionTitle')}
                </SortingFieldTitle>

                <Dropdown
                    trigger={['click']}
                    visible={visibleDirectionSelection}
                    onVisibleChange={(visible) =>
                        setVisibleDirectionSelection(
                            visible && !disabled && Boolean(field),
                        )
                    }
                    overlay={
                        <StyledDropdownItems>
                            {SORTING_DIRECTIONS.map((option) => (
                                <StyledDropdownItem
                                    key={option.label}
                                    onClick={() => {
                                        onChangeDirection(option.value);
                                        setVisibleDirectionSelection(false);
                                    }}
                                    className="dropdown-item"
                                >
                                    {option.label}
                                </StyledDropdownItem>
                            ))}
                        </StyledDropdownItems>
                    }
                >
                    <StyledDropdownSelector disabled={disabled || !field}>
                        {
                            SORTING_DIRECTIONS.find(
                                (option) => option.value === direction,
                            )?.label
                        }

                        <Arrow size={14} />
                    </StyledDropdownSelector>
                </Dropdown>
            </SortingFieldDirection>
            <SortingFieldReset>
                {showResetButton && (
                    <Button kind="secondary" size="small" onClick={() => onReset()}>
                        <FontAwesomeIcon icon={faXmark} />
                    </Button>
                )}
            </SortingFieldReset>
        </SortingFieldWrapper>
    );
};

export default SortingField;
