import { FC, useEffect, useState } from 'react';

import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { BlockCode } from '@components/Common/BlockCode';
import { Button } from '@components/Common/Button';
import QueryTable from '@components/Common/QueryTable';
import { useTranslation } from '@lib/i18n';
import { isClientSide } from '@lib/next-js-utils';

import SortingField from './SortingField';

export const DemonstrationOfSortingWrapper = styled.div`
    display: grid;
    grid-template-columns: minmax(auto, 450px) auto;
    gap: 20px;

    border: 3px dashed #b4b4b4;
    padding: var(--indent-xl);
    border-radius: var(--border-radius-xl);
    margin-top: 25px;

    .sql-code-block {
        margin: 0px auto !important;

        max-width: unset !important;
    }

    @media screen and (min-width: 1400px) and (max-width: 1570px) {
        grid-template-columns: auto;
    }

    @media screen and (min-width: 1265px) and (max-width: 1400px) {
        grid-template-columns: minmax(auto, 450px) auto;
    }

    @media screen and (max-width: 1265px) {
        grid-template-columns: auto;
    }
`;

export const QueryBuilder = styled.div`
    position: relative;
`;

export const TableWrapper = styled.div`
    overflow: hidden;

    & > div {
        border-radius: var(--border-radius-m);
    }
`;

export const SortingFields = styled.div`
    margin-bottom: 30px;
`;

export const fields = ['id', 'strField', 'numField', 'dateField'];
export const rows = [
    {
        id: '1',
        strField: 'Bbb',
        numField: 10,
        dateField: '01.01.2024',
    },
    {
        id: '2',
        strField: 'Abb',
        numField: 10,
        dateField: '04.01.2024',
    },
    {
        id: '3',
        strField: 'Abb',
        numField: 9,
        dateField: '02.01.2024',
    },
    {
        id: '4',
        strField: 'Aaa',
        numField: 9,
        dateField: '03.01.2024',
    },
    {
        id: '5',
        strField: 'Bbb',
        numField: 10,
        dateField: '02.01.2024',
    },
    {
        id: '6',
        strField: 'Abc',
        numField: 10,
        dateField: '03.01.2024',
    },
    {
        id: '7',
        strField: 'Bbb',
        numField: 9,
        dateField: '02.01.2024',
    },
    {
        id: '8',
        strField: 'Aba',
        numField: 8,
        dateField: '04.01.2024',
    },
];

const DEFAULT_SQL_QUERY = 'SELECT * FROM Table';

const COLORS = {
    FIRST: 'rgb(24 113 194 / 40%)',
    SECOND: 'rgb(224 49 48 / 40%)',
    THIRD: 'rgb(47 158 68 / 40%)',
};

const BORDER_RADIUS = {
    START: '8px 8px 0px 0px',
    END: '0px 0px 8px 8px',
};

const sortFunctionByField = {
    id: (a: number, b: number) => a - b,
    strField: (a: string, b: string) => a.localeCompare(b),
    numField: (a: number, b: number) => a - b,
    dateField: (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime(),
};

type IRow = (typeof rows)[0];

interface ISortFieldState {
    field?: string;
    direction?: 'ASC' | 'DESC';
}

type DemostrationOfSortingProps = {
    css?: string;
};

export const DemostrationOfSorting: FC<DemostrationOfSortingProps> = (props) => {
    const { t } = useTranslation('demo');
    const [sortedRows, setSortedRows] = useState<IRow[]>([]);
    const [sqlQuery, setSqlQuery] = useState(DEFAULT_SQL_QUERY);

    const [sortField1, setSortField1] = useState<ISortFieldState>({ field: '' });
    const [sortField2, setSortField2] = useState<ISortFieldState>({ field: '' });
    const [sortField3, setSortField3] = useState<ISortFieldState>({ field: '' });

    const onResetAll = () => {
        setSortField1({ field: '' });
        setSortField2({ field: '' });
        setSortField3({ field: '' });
    };

    useEffect(() => {
        if (isClientSide) {
            setSortedRows(rows);
        }
    }, []);

    useEffect(() => {
        let querySql = DEFAULT_SQL_QUERY;

        if (!sortField1.field) {
            querySql += `
 `;
        }

        if (sortField1.field) {
            querySql += ` ORDER BY 
  ${sortField1.field}`;

            if (sortField1.direction) {
                querySql += ' ' + sortField1.direction;
            }
        }

        if (sortField2.field) {
            querySql += ', ' + sortField2.field;

            if (sortField2.direction) {
                querySql += ' ' + sortField2.direction;
            }
        }

        if (sortField3.field) {
            querySql += ', ' + sortField3.field;

            if (sortField3.direction) {
                querySql += ' ' + sortField3.direction;
            }
        }

        setSqlQuery(querySql);

        setSortedRows(
            [...rows].sort((row1, row2) => {
                if (sortField1.field) {
                    // Если достаточно отсортировать по 1 полю сортировки, сортируем
                    if (row1[sortField1.field] !== row2[sortField1.field]) {
                        const firstRowField =
                            sortField1.direction !== 'DESC'
                                ? row1[sortField1.field]
                                : row2[sortField1.field];
                        const secondRowField =
                            sortField1.direction !== 'DESC'
                                ? row2[sortField1.field]
                                : row1[sortField1.field];

                        return sortFunctionByField[sortField1.field](
                            firstRowField,
                            secondRowField,
                        );
                    }
                }

                // Если по 1 полю сортировки записи эквивалентны, то сортируем
                // по 2 полю сортировки
                if (sortField2.field) {
                    if (row1[sortField2.field] !== row2[sortField2.field]) {
                        const firstRowField =
                            sortField2.direction !== 'DESC'
                                ? row1[sortField2.field]
                                : row2[sortField2.field];
                        const secondRowField =
                            sortField2.direction !== 'DESC'
                                ? row2[sortField2.field]
                                : row1[sortField2.field];

                        return sortFunctionByField[sortField2.field](
                            firstRowField,
                            secondRowField,
                        );
                    }
                }

                // Если по 1 и 2 полю сортировки записи эквивалентны, то сортируем
                // по 3 полю сортировки
                if (sortField3.field) {
                    if (row1[sortField3.field] !== row2[sortField3.field]) {
                        const firstRowField =
                            sortField3.direction !== 'DESC'
                                ? row1[sortField3.field]
                                : row2[sortField3.field];
                        const secondRowField =
                            sortField3.direction !== 'DESC'
                                ? row2[sortField3.field]
                                : row1[sortField3.field];

                        return sortFunctionByField[sortField3.field](
                            firstRowField,
                            secondRowField,
                        );
                    }
                }

                return 0;
            }),
        );
    }, [sortField1, sortField2, sortField3]);

    const fieldOptions = fields.map((field) => ({
        value: field,
        label: field,
    }));

    const availableFields = fields.filter(
        (field) =>
            ![sortField1.field, sortField2.field, sortField3.field].includes(field),
    );

    const availiableFieldOptions = availableFields.map((field) => ({
        value: field,
        label: field,
    }));

    const getTableCellStyle = ({
        row,
        field,
        index,
    }: {
        row: IRow;
        field: string;
        index: number;
    }) => {
        let background: string | undefined = undefined;
        let borderRadius: string | undefined = undefined;

        if (field === sortField1.field) {
            background = COLORS.FIRST;

            if (index === sortedRows.length - 1) {
                borderRadius = BORDER_RADIUS.END;
            }

            if (index === 0) {
                borderRadius = BORDER_RADIUS.START;
            }
        }

        if (sortField1.field && field === sortField2.field) {
            const prevElementIsEqualBySortField1 =
                row[sortField1.field] === sortedRows.at(index - 1)?.[sortField1.field];

            const nextElementIsEqualBySortField1 =
                row[sortField1.field] === sortedRows.at(index + 1)?.[sortField1.field];

            if (prevElementIsEqualBySortField1 || nextElementIsEqualBySortField1) {
                background = COLORS.SECOND;
            }

            if (prevElementIsEqualBySortField1 && !nextElementIsEqualBySortField1) {
                borderRadius = BORDER_RADIUS.END;
            }

            if (!prevElementIsEqualBySortField1 && nextElementIsEqualBySortField1) {
                borderRadius = BORDER_RADIUS.START;
            }
        }

        if (sortField1.field && sortField2.field && field === sortField3.field) {
            const prevElementIsEqualBySortField1 =
                row[sortField1.field] === sortedRows.at(index - 1)?.[sortField1.field] &&
                row[sortField2.field] === sortedRows.at(index - 1)?.[sortField2.field];

            const nextElementIsEqualBySortField1 =
                row[sortField1.field] === sortedRows.at(index + 1)?.[sortField1.field] &&
                row[sortField2.field] === sortedRows.at(index + 1)?.[sortField2.field];

            if (prevElementIsEqualBySortField1 || nextElementIsEqualBySortField1) {
                background = COLORS.THIRD;
            }

            if (prevElementIsEqualBySortField1 && !nextElementIsEqualBySortField1) {
                borderRadius = BORDER_RADIUS.END;
            }

            if (!prevElementIsEqualBySortField1 && nextElementIsEqualBySortField1) {
                borderRadius = BORDER_RADIUS.START;
            }
        }

        return { background, borderRadius };
    };

    if (sortedRows.length === 0) return null;

    return (
        <DemonstrationOfSortingWrapper {...props}>
            <QueryBuilder>
                <BlockCode>{sqlQuery}</BlockCode>
                <SortingFields>
                    <SortingField
                        sortOrder={1}
                        field={sortField1.field}
                        direction={sortField1.direction}
                        fieldOptions={fieldOptions}
                        availiableFieldOptions={availiableFieldOptions}
                        onChangeField={(field) =>
                            setSortField1({ field, direction: sortField1.direction })
                        }
                        onChangeDirection={(direction) =>
                            setSortField1({ field: sortField1.field, direction })
                        }
                        showResetButton={Boolean(sortField1.field && !sortField2.field)}
                        onReset={() =>
                            setSortField1({
                                field: undefined,
                                direction: undefined,
                            })
                        }
                    />
                    <SortingField
                        sortOrder={2}
                        field={sortField2.field}
                        direction={sortField2.direction}
                        fieldOptions={fieldOptions}
                        availiableFieldOptions={availiableFieldOptions}
                        onChangeField={(field) =>
                            setSortField2({ field, direction: sortField2.direction })
                        }
                        onChangeDirection={(direction) =>
                            setSortField2({ field: sortField2.field, direction })
                        }
                        showResetButton={Boolean(sortField2.field && !sortField3.field)}
                        onReset={() =>
                            setSortField2({
                                field: undefined,
                                direction: undefined,
                            })
                        }
                        disabled={!sortField1.field}
                    />
                    <SortingField
                        sortOrder={3}
                        field={sortField3.field}
                        direction={sortField3.direction}
                        fieldOptions={fieldOptions}
                        availiableFieldOptions={availiableFieldOptions}
                        onChangeField={(field) =>
                            setSortField3({ field, direction: sortField3.direction })
                        }
                        onChangeDirection={(direction) =>
                            setSortField3({ field: sortField3.field, direction })
                        }
                        showResetButton={Boolean(sortField3.field)}
                        onReset={() =>
                            setSortField3({
                                field: undefined,
                                direction: undefined,
                            })
                        }
                        disabled={!sortField2.field}
                    />
                </SortingFields>
                <Button onClick={() => onResetAll()}>
                    <FontAwesomeIcon icon={faRepeat} style={{ marginRight: 10 }} />
                    {t('sorting.restartDemo')}
                </Button>
            </QueryBuilder>

            <TableWrapper>
                <QueryTable
                    tableData={{ fields, rows: sortedRows }}
                    getTableCellStyle={getTableCellStyle}
                    maxLines={8}
                    animateRowsReplacement
                />
            </TableWrapper>
        </DemonstrationOfSortingWrapper>
    );
};

export default DemostrationOfSorting;
